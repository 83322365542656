import React from 'react'
import { QuizQuestionWrapper } from '../../QuizQuestionWrapper'
import { useGuestCount } from './GuestCount.hook'
import './GuestCount.scss'

const disabledDays = new Date()
disabledDays.setDate(disabledDays.getDate() + 1)

const GUESTS_COUNT = [
  { id: 'guest-count-0', title: '< 50', value: '0' },
  { id: 'guest-count-50', title: '50 - 100', value: '50' },
  { id: 'guest-count-100', title: '100 - 150', value: '100' },
  { id: 'guest-count-150', title: '150+', value: '200' },
]

export const GuestCount = () => {
  const { handleBack, handleRecordGuestCount, guestCount } = useGuestCount()

  return (
    <>
      <QuizQuestionWrapper
        titleStep="GUEST_COUNT"
        title="How many guests are you expecting?"
        nextAction={{
          disabled: true,
        }}
        backAction={{
          onAction: handleBack,
        }}
        customCss={{
          title: 'title-bold',
          content: 'guest-count-wrapper',
        }}
        step={5}
        total={7}
      >
        <div className="guest-count-section">
          <div className="guest-range-new">
            {GUESTS_COUNT.map((item) => (
              <div
                key={item.id}
                className={`item ${item.value === guestCount ? 'selected' : ''}`}
                onClick={() => handleRecordGuestCount(item.value.toString())}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
