/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useLocation } from '../../Location'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../../contextAPI'
import { PATH_URL } from 'src/helpers'

interface quizResponseI {
  key: string
  values: string[] | string
}

export const useNewPlanningStage = () => {
  const history = useHistory()
  const { data, setQuizScreen, weddingDateData } = useContext(QuizQuestionContext)
  const [stage, setStage] = useState<string>(data.planningStage?.stage ?? '')
  const location = useLocation()

  const handleNext = async (value: string) => {
    const currentTempPoppyData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    let currentQuizResponseFromLocal = currentTempPoppyData.quizResponses || null
    if (currentQuizResponseFromLocal && currentQuizResponseFromLocal.length > 0) {
      const stageIndex = currentQuizResponseFromLocal.findIndex((e: quizResponseI) => e.key === 'PLANNING_STAGE')
      if (stageIndex > -1) {
        currentQuizResponseFromLocal[stageIndex].values = value
      } else {
        currentQuizResponseFromLocal.push({
          key: 'PLANNING_STAGE',
          values: value,
        })
      }
    } else {
      currentQuizResponseFromLocal = [{ key: 'PLANNING_STAGE', values: value }]
    }
    localStorage.setItem(
      'tempPoppySessionData',
      JSON.stringify({
        ...currentTempPoppyData,
        quizResponses: currentQuizResponseFromLocal,
      })
    )
    history.push({ pathname: PATH_URL.GUEST_COUNT })
    setQuizScreen(SCREEN_QUIZ_QUESTION.GUEST_COUNT)
  }

  const handleBack = async () => {
    if (window.history.length > 1) {
      history.goBack()
    } else {
      history.push({ pathname: PATH_URL.YOUR_DETAILS })
      setQuizScreen(SCREEN_QUIZ_QUESTION.YOUR_DETAILS)
    }
  }

  const handleChangeStage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setStage(value)

    setTimeout(() => {
      handleNext(value)
    }, 1000)
  }

  const handleClickStage = (value: string) => {
    if (value === stage) {
      handleNext(value)
    }
  }

  useEffect(() => {
    const dataFromLocal = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    const quizResponsesFromLocal = dataFromLocal?.quizResponses || null
    const stageDataFromLocal = quizResponsesFromLocal?.find((e: quizResponseI) => e.key === 'PLANNING_STAGE')
    setStage(data.planningStage?.stage || stageDataFromLocal?.values || '')
  }, [data.planningStage?.stage])

  return {
    data,
    location,
    weddingDateData,
    handleBack,
    stage,
    handleChangeStage,
    handleClickStage,
  }
}
