import React from 'react'
import { QuizQuestionWrapper } from '../../QuizQuestionWrapper'
import { useContactInfo } from './ContactInfo.hook'
import './ContactInfo.scss'
import { OutlinedTextField, PhoneNumber } from 'src/common/components'
import { Dropdown } from 'src/common/components/select/Dropdown'
import { REFERRAL_SOURCES } from 'src/pages/shared'
import { REDIRECT_PATH } from 'src/helpers'

export const ContactInfo = () => {
  const {
    handleNext,
    handleBack,
    weddingDateData,
    formError,
    handleChangeInput,
    referralSource,
    handleChangeReferralSource,
    handleChangePhoneNumber,
    nextDisabled,
    loading,
  } = useContactInfo()

  return (
    <>
      <QuizQuestionWrapper
        titleStep="CONTACT INFO"
        title="Ready to see your proposal?"
        subtitle="(You’ll use this email to login later)"
        nextAction={{
          disabled: true,
        }}
        backAction={{
          onAction: handleBack,
        }}
        customCss={{
          title: 'title-bold',
          content: 'contact-info-wrapper',
        }}
        step={7}
        total={7}
      >
        <div className="contact-info-section">
          <OutlinedTextField
            wrapperClass="mb-10px"
            inputClass="email"
            id="emailAddressId"
            value={weddingDateData.emailAddress ?? ''}
            type="text"
            name="emailAddress"
            onChange={handleChangeInput}
            label="Email address"
            isError={formError.emailAddress}
            errorMsg="Invalid email!"
          />

          <div className="input-wrapper mb-10px">
            <div className={`floating-label-wrapper ${weddingDateData.phoneNumber ? 'has-value' : ''}`}>
              <label className="label">Phone number</label>
              <PhoneNumber
                id="phoneNumberId"
                country="US"
                value={weddingDateData.phoneNumber}
                onChange={handleChangePhoneNumber}
                inputMode="tel"
                className={`input w-100 ${formError.phoneNumber ? 'border-red' : ''}`}
                placeholder=" "
                name="phoneNumber"
              />
            </div>
            {formError.phoneNumber ? (
              <div className="error">Invalid phone number!</div>
            ) : (
              <div className="error">&nbsp;</div>
            )}
          </div>

          <h2 className="ref-text">How did you hear about us?</h2>
          <Dropdown
            className="ref-dropdown"
            options={REFERRAL_SOURCES}
            placeholder="Choose one"
            value={referralSource ?? undefined}
            handleChange={handleChangeReferralSource}
          />
          <div className="action-box-wrapper mt-34px mb-30px">
            <button
              disabled={nextDisabled || loading}
              className={`button white-space-wrap see-your-proposal-btn ${loading ? 'loading' : ''}`}
              onClick={handleNext}
            >
              SEE YOUR PROPOSAL
            </button>
          </div>
          <p className="get-started-section__compliance notice-text">
            By signing up via text, you agree to receive recurring automated marketing text messages from Poppy Flowers
            at the cell number used when signing up. Consent is not a condition of any purchase. Msg & data rates may
            apply. Unsubscribe at anytime. See{' '}
            <a href={REDIRECT_PATH.TERMS} target="_blank" rel="noreferrer">
              Terms
            </a>
            {' & '}
            <a href={REDIRECT_PATH.PRIVACY} target="_blank" rel="noreferrer">
              Privacy.
            </a>
          </p>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
