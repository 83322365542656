import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from 'src/pages/quiz/contextAPI'
import { useNewLocation } from '../NewLocation/NewLocation.hook'
import moment from 'moment'
import { PATH_API, PATH_URL, redirect } from 'src/helpers/path'
import { EVENT_QUIZ } from 'src/pages/quiz/contextAPI'
import { postHttp } from 'src/api'
import { EVENT_LOCATION_TYPE } from 'src/types'

export const useAvailability = () => {
  const history = useHistory()
  const location = useNewLocation()
  const { data, setQuizScreen, recordResponse } = useContext(QuizQuestionContext)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)
  const {
    handleGGPlaceSelected,
    handleChange,
    locationSelected,
    hasValue: locationHasValue,
    handleBlur,
    handleFocus,
    locationText,
  } = location
  const [loading, setLoading] = useState(false)
  const [serviceable, setServiceable] = useState(true)
  const [unserviceableReason, setUnserviceableReason] = useState()

  const handleNext = async () => {
    setLoading(true)
    const currentLocalStorage = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    let currentQuizResponse = currentLocalStorage.quizResponses
    if (currentQuizResponse && currentQuizResponse.length > 0) {
      const eventDateKeyIndex = currentQuizResponse.findIndex(
        (e: { key: string; values: string[] | string }) => e.key === 'EVENT_DATE'
      )
      if (eventDateKeyIndex > -1) {
        currentQuizResponse[eventDateKeyIndex].values = moment(selectedDate).format('YYYY-MM-DD')
      } else {
        currentLocalStorage.push({
          key: 'EVENT_DATE',
          values: moment(selectedDate).format('YYYY-MM-DD'),
        })
      }
    } else {
      currentQuizResponse = [{ key: 'EVENT_DATE', values: moment(selectedDate).format('YYYY-MM-DD') }]
    }
    const result = await postHttp(PATH_API.checkAvailability(), {
      eventDateValue: moment(selectedDate).format('YYYY-MM-DD'),
      placeId: locationSelected.find((e) => e.type === EVENT_LOCATION_TYPE.CEREMONY)?.placeId,
      locationText,
    })
    if (result.data && result.data.serviceable === false) {
      setLoading(false)
      setServiceable(false)
      setUnserviceableReason(result.data.unserviceableReason)
      // redirect is here 0830
    } else {
      const totalWeddingNumber = parseInt(result.data?.totalWeddings) || 0
      const venueName = result.data?.venueName || ''
      localStorage.setItem(
        'tempPoppySessionData',
        JSON.stringify({
          ...currentLocalStorage,
          eventVenues: locationSelected,
          quizResponses: currentQuizResponse,
          totalWeddingNumber,
          venueName,
        })
      )
      setLoading(false)
      history.push({ pathname: PATH_URL.YOUR_DETAILS })
      setQuizScreen(SCREEN_QUIZ_QUESTION.YOUR_DETAILS)
    }
  }

  const handleBack = () => {
    history.goBack()
    setQuizScreen(SCREEN_QUIZ_QUESTION.COLOR_PALETTE_PAGE)
  }

  useEffect(() => {
    const currentTempData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    const currentDateData = currentTempData?.quizResponses?.find(
      (e: { key: string; values: string[] | string }) => e.key === EVENT_QUIZ.EVENT_DATE
    )
    if (currentDateData) {
      setSelectedDate(new Date(currentDateData.values))
    }
  }, [])

  const nextDisabled = useMemo(() => {
    return !selectedDate || !locationHasValue
  }, [selectedDate, locationHasValue])

  return {
    data,
    nextDisabled,
    loading,
    handleBack,
    handleNext,
    recordResponse,
    selectedDate,
    location,
    setSelectedDate,
    handleGGPlaceSelected,
    handleChange,
    locationSelected,
    locationHasValue,
    handleBlur,
    handleFocus,
    serviceable,
    setServiceable,
    setUnserviceableReason,
    unserviceableReason,
  }
}
