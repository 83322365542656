import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import './assets/scss/style.scss'

import { StyleInfo } from './pages/styleInfo'
import { ResultsPage } from './pages/results'
import { ScheduleCall } from './pages/scheduleCall'
import { CallScheduled } from './pages/callScheduled'

import { PATH_URL } from './helpers'
import { useAppBase } from './helpers/app'
import { LoaderFullScreen, LoaderFullScreenWithTestimonial } from './common'
import { CustomerExperiencePage } from './pages/customerExperience'
import { MoodBoard } from './pages/moodboard'
import { CallScheduler } from './pages/callScheduler'
import { PostMoodboard } from './pages/postMoodboard'
import { QuizQuestion } from './pages/quiz/QuizQuestionProvider'
import { useSegmentPage } from './helpers/hooks/useSegmentPage'
import { ENV } from './config/env'

const AppBase: React.FC = () => {
  const history = useHistory()

  if (history.location.pathname === '/') {
    console.log("redirecting");
    const currentParams = new URLSearchParams(window.location.search)

    const redirectTo = (baseUrl: string, additionalParams: Record<string, string> = {}): void => {
      const url = new URL(baseUrl, window.location.origin)
      currentParams.forEach((value, key) => {
        if (!additionalParams.hasOwnProperty(key)) {
          url.searchParams.append(key, value)
        }
      })
      for (const [key, value] of Object.entries(additionalParams)) {
        url.searchParams.set(key, value)
      }
      location.replace(url.toString())
    }

    console.log("dotenv", process.env.REACT_APP_DEFAULT_EXPERIENCE)
    console.log("ENV.", ENV.REACT_APP_DEFAULT_EXPERIENCE)

    if (ENV.REACT_APP_DEFAULT_EXPERIENCE === 'legacy' || window.location.search.includes('quizData')) {
      redirectTo(PATH_URL.DETAILS)
    } else {
      redirectTo(PATH_URL.NEW_COLOR_PALETTE_PAGE)
    }
  }

  const { isLoading, isLoadingTestimonial } = useAppBase()
  useSegmentPage()
  return (
    <>
      {isLoading && <LoaderFullScreen />}
      {isLoadingTestimonial && <LoaderFullScreenWithTestimonial />}
      <Switch>
        <Route path={PATH_URL.STYLE_INFO} component={StyleInfo} />
        <Route path={PATH_URL.YOUR_POPPY_PROPOSAL} component={ResultsPage} />
        <Route path={PATH_URL.CALL_SCHEDULED} component={CallScheduled} />
        <Route path={PATH_URL.$500_CUSTOMER} component={CustomerExperiencePage} />
        <Route path={PATH_URL.SCHEDULE_YOUR_CONSULTATION} component={ScheduleCall} />
        <Route path={PATH_URL.MOODBOARD} component={MoodBoard} />
        <Route path={PATH_URL.POST_MOODBOARD} component={PostMoodboard} />
        <Route path={PATH_URL.SCHEDULE_A_CALL} component={CallScheduler} />
        <Route path={PATH_URL.SWIPING} component={QuizQuestion} />
        <Route path="*" component={QuizQuestion} />
      </Switch>
    </>
  )
}

export default AppBase
