/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useLocation } from '../../Location'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../../contextAPI'
import { combineToFullName, PATH_URL, splitFullName, validate } from 'src/helpers'
import { isObjectEmpty } from 'src/helpers'

interface formError {
  firstName?: boolean
  lastName?: boolean
  fullName?: boolean
  partnerName?: boolean
  partnerFirstName?: boolean
  partnerLastName?: boolean
}

interface quizResponseI {
  key: string
  values: string[] | string
}

export const useYourDetails = () => {
  const history = useHistory()
  const { data, setQuizScreen, weddingDateData, setWeddingDateDataState } = useContext(QuizQuestionContext)
  const location = useLocation()
  const [formError, setFormError] = useState<formError>({})

  const isFullNameError = useMemo(() => !validate.leastOneLetter(weddingDateData.fullName || ''), [
    weddingDateData.fullName,
  ])
  const isPartnerNameError = useMemo(() => !validate.leastOneLetter(weddingDateData.partnerName || ''), [
    weddingDateData.partnerName,
  ])

  const formInvalid = () => {
    setFormError({
      fullName: isFullNameError,
      partnerName: isPartnerNameError,
    })
    return isFullNameError || isPartnerNameError
  }

  const handleNext = async () => {
    if (formInvalid()) {
      return
    }
    const currentTempPoppyData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    const currentCustomerInfoLocal = currentTempPoppyData?.customerInfo || {}
    if (!isObjectEmpty(currentTempPoppyData)) {
      const customerInfo = {
        ...currentCustomerInfoLocal,
        firstName: weddingDateData.firstName?.trim() || '',
        lastName: weddingDateData.lastName?.trim() || '',
        partnerName: weddingDateData.partnerName?.trim() || '',
      }
      localStorage.setItem(
        'tempPoppySessionData',
        JSON.stringify({
          ...currentTempPoppyData,
          customerInfo,
        })
      )
    }
    history.push({ pathname: PATH_URL.PLANNING_STAGE })
    setQuizScreen(SCREEN_QUIZ_QUESTION.PLANNING_STAGE)
  }

  const handleBack = async () => {
    if (window.history.length > 1) {
      history.goBack()
    } else {
      history.push({ pathname: PATH_URL.NEW_AVAILABILITY_PAGE })
      setQuizScreen(SCREEN_QUIZ_QUESTION.NEW_AVAILABILITY_PAGE)
    }
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'fullName') {
      const [firstName, lastName] = splitFullName(value)
      setWeddingDateDataState({ ...weddingDateData, [name]: value, firstName, lastName })
      setFormError((data) => ({ ...data, [name]: false, firstName: false, lastName: false }))
      return
    }
    if (name === 'partnerName') {
      const [partnerFirstName, partnerLastName] = splitFullName(value)
      setWeddingDateDataState({ ...weddingDateData, [name]: value, partnerFirstName, partnerLastName })
      setFormError((data) => ({ ...data, [name]: false, partnerFirstName: false, partnerLastName: false }))
      return
    }
    setWeddingDateDataState({ ...weddingDateData, [name]: value })
    setFormError((data) => ({ ...data, [name]: false }))
  }

  const nextDisabled = useMemo(() => {
    const check = !weddingDateData.fullName || !weddingDateData.partnerName

    return check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingDateData])

  useEffect(() => {
    setFormError((data) => ({ ...data, location: false }))
  }, [location.locationSelected])

  useEffect(() => {
    const currentTempDataFromLocalStorage = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    if (data.weddingDate) {
      const [partnerFirstName, partnerLastName] = splitFullName(
        data.weddingDate?.partnerName?.trim() || currentTempDataFromLocalStorage.customerInfo?.partnerName?.trim() || ''
      )
      setWeddingDateDataState({
        weddingDate:
          data?.weddingDate?.weddingDate ||
          currentTempDataFromLocalStorage.quizResponses?.find((e: quizResponseI) => e.key === 'EVENT_DATE') ||
          '',
        emailAddress: data?.weddingDate?.emailAddress,
        phoneNumber: data?.weddingDate?.phoneNumber,

        firstName: data?.weddingDate?.firstName || currentTempDataFromLocalStorage.customerInfo?.firstName || '',
        lastName: data?.weddingDate?.lastName || currentTempDataFromLocalStorage.customerInfo?.lastName || '',
        fullName:
          data?.weddingDate?.fullName?.trim() ||
          combineToFullName(
            currentTempDataFromLocalStorage.customerInfo?.firstName || '',
            currentTempDataFromLocalStorage.customerInfo?.lastName || ''
          ) ||
          '',

        partnerFirstName: partnerFirstName || '',
        partnerLastName: partnerLastName || '',
        partnerName: data?.weddingDate?.partnerName || currentTempDataFromLocalStorage.customerInfo?.partnerName || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setWeddingDateDataState])

  return {
    data,
    location,
    nextDisabled,
    weddingDateData,
    formError,
    handleNext,
    handleBack,
    handleChangeInput,
  }
}
