import React from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { ENV } from 'src/config/env'
import './NewLocation.scss'

// import { State } from './State'
import { EVENT_LOCATION_TYPE, ILocationQuiz } from 'src/types'

interface ILocationItemProps {
  data: ILocationQuiz
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void
  /* eslint-disable @typescript-eslint/no-explicit-any */
  handleGGPlaceSelected: (place: any, index: number) => void
  index: number
  inputProps?: {
    showLabel?: boolean
    placeholder?: string
    className?: string
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
}

export const NewLocationItem: React.FC<ILocationItemProps> = (props: ILocationItemProps) => {
  const { data, index, inputProps = { showLabel: true }, handleGGPlaceSelected, handleChange } = props

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: ENV.REACT_APP_GG_MAP_API_KEY,
    onPlaceSelected: (place) => handleGGPlaceSelected(place, index),
    options: {
      fields: ['address_components', 'place_id', 'formatted_address', 'name', 'vicinity'],
      types: ['establishment', 'geocode'],
      componentRestrictions: { country: 'us' },
    },
  })

  return (
    <>
      <div className="quiz-guest-location">
        <div className={`floating-label-wrapper ${data.place ? 'has-value' : ''}`}>
          <input
            ref={ref}
            id="placeId"
            className={inputProps.className ?? 'input w-100'}
            value={data.place ?? ''}
            type="text"
            name="place"
            placeholder={inputProps.placeholder || ''}
            onChange={(e) => handleChange(e, index)}
            onBlur={inputProps?.onBlur}
            onFocus={inputProps?.onFocus}
            autoComplete="off"
          />
          {inputProps.showLabel && (
            <label className="filled" htmlFor="placeId">
              Wedding Location
            </label>
          )}
        </div>
      </div>
    </>
  )
}

export interface ILocationProps {
  locationSelected: ILocationQuiz[]
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void
  /* eslint-disable @typescript-eslint/no-explicit-any */
  handleGGPlaceSelected: (place: any, index: number) => void
  inputProps?: {
    showLabel?: boolean
    placeholder?: string
    className?: string
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
  locationType?: EVENT_LOCATION_TYPE
}

export const NewLocation = (props: ILocationProps) => {
  const { locationSelected, inputProps, locationType, handleChange, handleGGPlaceSelected } = props

  const ceremonyLocation = locationType
    ? locationSelected.find((location) => location.type === locationType) ?? locationSelected[0]
    : locationSelected[0]

  return (
    <div>
      <NewLocationItem
        data={ceremonyLocation}
        handleChange={handleChange}
        handleGGPlaceSelected={handleGGPlaceSelected}
        inputProps={inputProps}
        index={0}
      />
    </div>
  )
}
