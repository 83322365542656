import React, {
  useEffect,
  // useEffect,
  useState,
} from 'react'
import DayPicker, { DayModifiers } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import './DatePicker.scss'
import moment from 'moment'
import { Dropdown } from 'src/common/components/select/Dropdown'
import { IOption } from 'src/types/common'
import { monthArray, generateYearArray } from './dateUtils'
import { EVENT_QUIZ } from 'src/pages/quiz/contextAPI'

interface DatePickerProps {
  selectedDate: Date | undefined
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>
}

const DatePicker = (props: DatePickerProps) => {
  const { selectedDate, setSelectedDate } = props

  const [month, setMonth] = useState<IOption | null>({
    value: moment().month().toString(),
    label: moment().format('MMMM'),
  })

  const yearArray = generateYearArray(10)
  const [year, setYear] = useState<IOption | null>({
    value: moment().year().toString(),
    label: moment().format('YYYY'),
  })

  const handleDateClick = (date: Date, modifier?: DayModifiers) => {
    if (!modifier || (modifier && !modifier.disabled)) {
      setSelectedDate(date)
      const newMonthFromDate = moment(date).month()
      const currentMonthValue = parseInt(month?.value || '0')
      if (newMonthFromDate !== currentMonthValue) {
        setMonth({ value: newMonthFromDate.toString(), label: moment(date).format('MMMM') })
      }
    }
  }
  const handleMonthClick = (option: IOption | null) => {
    if (!option) return
    setMonth(option)
    if (selectedDate) {
      const newDate = new Date(selectedDate).setMonth(parseInt(option.value))
      if (moment(newDate).isAfter(moment())) {
        setSelectedDate(new Date(newDate))
      } else {
        setSelectedDate(new Date())
      }
    } else {
      const newDate = new Date().setMonth(parseInt(option.value))
      setSelectedDate(new Date(newDate))
    }
  }

  const handleYearClick = (option: IOption | null) => {
    if (!option) return
    setYear(option)
    if (selectedDate) {
      const newDate = new Date(selectedDate).setFullYear(parseInt(option.value))
      if (moment(newDate).isAfter(moment())) {
        setSelectedDate(new Date(newDate))
      } else {
        setSelectedDate(new Date())
      }
    } else {
      const newDate = new Date().setFullYear(parseInt(option.value))
      setSelectedDate(new Date(newDate))
    }
  }

  useEffect(() => {
    const currentLocalStorage = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    const currentDateData = currentLocalStorage?.quizResponses?.find(
      (e: { key: string; values: string[] | string }) => e.key === EVENT_QUIZ.EVENT_DATE
    )
    if (currentDateData) {
      setSelectedDate(new Date(currentDateData.values))
      setMonth({
        value: moment(currentDateData.values).month().toString(),
        label: moment(currentDateData.values).format('MMMM'),
      })
      setYear({
        value: moment(currentDateData.values).year().toString(),
        label: moment(currentDateData.values).format('YYYY'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="date-range-picker">
      <div className="upper-filter">
        <div className="month-select">
          <Dropdown
            className="available-month-dropdown"
            options={monthArray}
            placeholder="Month"
            value={month ?? undefined}
            handleChange={handleMonthClick}
          />
        </div>
        <div className="year-select">
          <Dropdown
            className="available-year-dropdown"
            options={yearArray}
            placeholder="Year"
            value={year ?? undefined}
            handleChange={handleYearClick}
          />
        </div>
      </div>

      <DayPicker
        className="Selectable"
        selectedDays={selectedDate}
        month={selectedDate}
        onDayClick={handleDateClick}
        navbarElement={() => <></>}
        captionElement={() => <></>}
        disabledDays={{ before: new Date() }}
      ></DayPicker>
    </div>
  )
}

export default DatePicker
