import React, { useContext } from 'react'
import { PATH_URL, REDIRECT_PATH } from 'src/helpers'
import { SESSION_NAME_LOCAL } from 'src/helpers/app'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../contextAPI'
import { QuizQuestionWrapper } from '../QuizQuestionWrapper'
import './style.scss'

export const EndQuiz: React.FC = () => {
  const { recordResponse, setQuizScreen } = useContext(QuizQuestionContext)

  const handleExit = () => {
    console.log('exit quiz')

    // remove session_id after user exit => allow user can take the other quiz with new email
    localStorage.setItem(SESSION_NAME_LOCAL, '')
    localStorage.setItem('tempPoppySessionData', '{}')

    if (window.history.length > 1) {
      // window.history.back()
      setQuizScreen(SCREEN_QUIZ_QUESTION.MET_BEFORE)
      window.location.href = PATH_URL.EXISTING_PROPOSAL
    } else {
      window.location.href = REDIRECT_PATH.HOME
    }
  }
  return (
    <QuizQuestionWrapper titleStep="Getting Started" backAction={{ disabled: true }} className="end-quiz">
      <div className="end-quiz-wrapper">
        <h2 className="title">You’ll hear from us soon!</h2>
        <p className="description mt-28px">
          Thank you for letting us know. Our team will reach out to you soon. You can always contact us at
          weddings@poppyflowers.com.
        </p>
        <p className="description mt-24px">
          Existing proposals can be found at{' '}
          <a
            href={recordResponse?.proposal?.proposalUrl || 'https://customers.poppyflowers.com'}
            target="_blank"
            rel="noreferrer"
          >
            https://customers.poppyflowers.com
          </a>
          .
        </p>
        <div className="action-box-wrapper mt-30px">
          <button className="button white-space-wrap" onClick={handleExit}>
            Exit
          </button>
        </div>
      </div>
    </QuizQuestionWrapper>
  )
}
