import { ILocationQuiz } from 'src/types'
import { PATH_URL } from './path'

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
  window.matchMedia('only screen and (max-width: 700px)').matches

export const removeExtraSpace = (str: string) => str.replace(/\s+/g, ' ').trim()

export const isVerticalMobile = () => isMobile && (window.orientation == 0 || window.orientation == 180)

export const splitFullName = (fullName: string) => {
  let firstName = ''
  let lastName = ''
  if (fullName) {
    fullName = removeExtraSpace(fullName)
    const arr = fullName.split(' ')
    if (arr.length > 0) {
      if (arr.length > 1) {
        lastName = (arr.pop() || '').trim()
        const ind = fullName.indexOf(lastName)
        if (ind > -1) {
          firstName = fullName.substring(0, ind).trim()
        }
      } else {
        firstName = (arr[0] || '').trim()
      }
    }
  }
  return [firstName, lastName]
}

export const combineToFullName = (firstName?: string, lastName?: string) => {
  return `${firstName || ''}${firstName ? ' ' : ''}${lastName || ''}`
}

export const getQueryParameters = (additionalParams?: { [key: string]: unknown }) => {
  let search = window.location.search
  if (!search) {
    const searchSplit = window.location.href.split('?')
    search = searchSplit.length > 1 ? searchSplit[1] : ''
  }
  const currentParams = new URLSearchParams(search)
  if (additionalParams) {
    for (const [key, value] of Object.entries(additionalParams)) {
      currentParams.set(key, value as string)
    }
  }
  return currentParams
}

export const bindLocationToPlace = (location: ILocationQuiz) => {
  let place = ''

  if (location.venue) {
    place = location.venue
  }
  if (location.streetAddress) {
    place += `, ${location.streetAddress}`
  }
  if (location.city) {
    place += `, ${location.city}`
  }
  if (location.state) {
    place += `, ${location.state}`
  }
  if (location.zipCode) {
    place += ` ${location.zipCode}`
  }
  if (location.country) {
    place += `, ${location.country}`
  }
  return place
}

export const isObjectEmpty = (obj: Record<string, unknown>) => {
  for (const prop in obj) {
    if (prop) {
      return false
    }
  }
  return true
}

export const shouldShowSectionHeader = () => {
  const routeWillBeHidden = [
    PATH_URL.NEW_COLOR_PALETTE_PAGE,
    PATH_URL.NEW_AVAILABILITY_PAGE,
    PATH_URL.YOUR_DETAILS,
    PATH_URL.PLANNING_STAGE,
    PATH_URL.GUEST_COUNT,
    PATH_URL.NEW_BUDGET_PAGE,
    PATH_URL.CONTACT_INFO,
  ]

  return !routeWillBeHidden.includes(window.location.pathname)
}
