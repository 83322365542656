import React from 'react'
import { useTriggerEvent } from 'src/common/hook'
import './RadioWithIcon.scss'

export type IRadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  classNames?: {
    container?: string
    label?: string
  }
}

export const RadioWithIcon: React.FC<IRadioProps> = (props: IRadioProps) => {
  const { children, classNames, ...radioProps } = props

  const { triggerRef, handleEnterTrigger } = useTriggerEvent()

  return (
    <div className={`radio-with-icon ${classNames?.container ?? ''}`}>
      <input ref={triggerRef} tabIndex={-1} id={props.id} name={props.name} type="radio" {...radioProps} />
      <label
        tabIndex={0}
        htmlFor={props.id}
        className={`radio-label d-flex ${classNames?.label ?? ''}`}
        onKeyUp={handleEnterTrigger}
      >
        <span>{children}</span>
      </label>
    </div>
  )
}
