import React from 'react'
import { DayPickerText } from 'src/common/components/picker'
import { useWeddingDate } from './WeddingDate.hook'
import { QuizQuestionWrapper } from './QuizQuestionWrapper'
import { isMobile } from 'src/helpers'
import { Location } from './Location'
import { OutlinedTextField, Radio } from 'src/common/components'
import { PhoneNumber } from 'src/common'
import { QuizExperience, REFERRAL_SOURCES, STAGES } from 'src/pages/shared'
import calendarIcon from 'src/assets/icons/calendar-icon.svg'
import { Dropdown } from 'src/common/components/select/Dropdown'
import { EVENT_LOCATION_TYPE } from 'src/types'
import { REDIRECT_PATH } from 'src/helpers'

const disabledDays = new Date()
disabledDays.setDate(disabledDays.getDate() + 1)

export const WeddingDate = () => {
  const {
    weddingDateData,
    nextDisabled,
    location,
    formError,
    handleNext,
    handleDayChangeBigDay,
    handleChangeInput,
    handleBlurInput,
    handleBlurPhoneNumber,
    handleChangePhoneNumber,
    stage,
    handleChangeStage,
    loading,
    referralSource,
    handleChangeReferralSource,
    // handleBlurReferralSource,
    experiment,
  } = useWeddingDate()

  const { handleGGPlaceSelected, handleChange, locationSelected, hasValue: locationHasValue } = location

  const renderYourName = () => {
    if (experiment.includes(QuizExperience.LAST_NAME_HIDDEN))
      return (
        <OutlinedTextField
          wrapperClass="mb-10px"
          id="firstNameId"
          value={weddingDateData.fullName ?? ''}
          type="text"
          name="fullName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Your name"
          isError={formError.fullName}
          errorMsg="Your name is required!"
        />
      )
    return (
      <>
        <OutlinedTextField
          wrapperClass="mb-10px md-25 sm-50"
          id="firstNameId"
          value={weddingDateData.firstName ?? ''}
          type="text"
          name="firstName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="First name"
          isError={formError.firstName}
          errorMsg="First name is required!"
        />
        <OutlinedTextField
          wrapperClass="mb-10px md-25 sm-50"
          id="lastNameId"
          value={weddingDateData.lastName ?? ''}
          type="text"
          name="lastName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Last name"
          isError={formError.lastName}
          errorMsg="Last name is required!"
        />
      </>
    )
  }
  const renderPartnerName = () => {
    if (experiment.includes(QuizExperience.LAST_NAME_HIDDEN))
      return (
        <OutlinedTextField
          wrapperClass="mb-10px"
          id="firstNameId"
          value={weddingDateData.partnerName ?? ''}
          type="text"
          name="partnerName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Your partner's name"
          isError={formError.partnerName}
          errorMsg="Your partner's name is required!"
        />
      )
    return (
      <>
        <OutlinedTextField
          wrapperClass="mb-10px md-25 sm-50"
          id="partnerFirstNameId"
          value={weddingDateData.partnerFirstName ?? ''}
          type="text"
          name="partnerFirstName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Partner’s first name"
          isError={formError.partnerFirstName}
          errorMsg="Partner first name is required!"
        />

        <OutlinedTextField
          wrapperClass="mb-10px md-25 sm-50"
          id="partnerLastNameId"
          value={weddingDateData.partnerLastName ?? ''}
          type="text"
          name="partnerLastName"
          onChange={handleChangeInput}
          // onBlur={handleBlurInput}
          label="Partner’s last name"
          isError={formError.partnerLastName}
          errorMsg="Partner last name is required!"
        />
      </>
    )
  }

  return (
    <>
      <QuizQuestionWrapper
        titleStep="Getting Started"
        title="Let’s check your date"
        subtitle="Let us know where and when you’re getting married so we can check our availability."
        nextAction={{
          disabled: nextDisabled,
          onAction: handleNext,
        }}
        backAction={{
          disabled: true,
        }}
        customCss={{
          title: 'title-bold',
          content: '',
        }}
        step={1}
        total={4}
      >
        <div className="get-started-section mt-24px mb-30px">
          <div className="quiz-wedding_date">
            {renderYourName()}
            {renderPartnerName()}

            <OutlinedTextField
              wrapperClass="mb-10px"
              inputClass="email"
              id="emailAddressId"
              value={weddingDateData.emailAddress ?? ''}
              type="text"
              name="emailAddress"
              onChange={handleChangeInput}
              onBlur={handleBlurInput}
              label="Email address"
              isError={formError.emailAddress}
              errorMsg="Invalid email!"
            />

            <div className="input-wrapper mb-10px">
              <div className={`floating-label-wrapper ${weddingDateData.phoneNumber ? 'has-value' : ''}`}>
                <label className="label">Phone number</label>
                <PhoneNumber
                  id="phoneNumberId"
                  country="US"
                  value={weddingDateData.phoneNumber}
                  onChange={handleChangePhoneNumber}
                  onBlur={handleBlurPhoneNumber}
                  inputMode="tel"
                  className={`input w-100 ${formError.phoneNumber ? 'border-red' : ''}`}
                  placeholder=" "
                  name="phoneNumber"
                />
              </div>
              {formError.phoneNumber ? (
                <div className="error">Invalid phone number!</div>
              ) : (
                <div className="error">&nbsp;</div>
              )}
            </div>

            <div className="input-wrapper mb-10px">
              <div className={`floating-label-wrapper ${locationHasValue ? 'has-value' : ''}`}>
                <label className="label">Wedding location</label>
                <Location
                  handleChange={handleChange}
                  locationSelected={locationSelected}
                  handleGGPlaceSelected={handleGGPlaceSelected}
                  inputProps={{ showLabel: false }}
                  locationType={EVENT_LOCATION_TYPE.CEREMONY}
                />
              </div>
              {formError.location ? (
                <div className="error">Location is required!</div>
              ) : (
                <div className="error">&nbsp;</div>
              )}
            </div>

            <div className="input-wrapper mb-10px">
              <div
                className={`floating-label-wrapper wedding_date-date ${
                  weddingDateData?.weddingDate ? 'has-value' : ''
                }`}
              >
                <label className="label">Wedding date</label>
                <DayPickerText
                  placeholder=""
                  inputProps={{
                    inputMode: 'none',
                    readOnly: isMobile,
                    // onBlur: handleBlurInput,
                    name: 'weddingDate',
                    className: `input w-100 ${formError.weddingDate ? 'border-red' : ''}`,
                    icon: <img src={calendarIcon} />,
                  }}
                  value={weddingDateData?.weddingDate}
                  onDayChange={handleDayChangeBigDay}
                  dayPickerProps={{
                    disabledDays: {
                      before: disabledDays,
                    },
                  }}
                />
              </div>
              {formError.weddingDate ? (
                <div className="error">Invalid wedding date!</div>
              ) : (
                <div className="error">&nbsp;</div>
              )}
            </div>
          </div>
          <div className="quiz-column-wrapper">
            {!experiment.includes(QuizExperience.ATTRIBUTION_HIDDEN) && (
              <>
                <div className="quiz-guest-booked mt-0px">
                  <div className="quiz-question-header">How did you hear about us? </div>
                  <Dropdown
                    className="quiz-social-dropdown"
                    options={REFERRAL_SOURCES}
                    placeholder="Choose one"
                    value={referralSource ?? undefined}
                    handleChange={handleChangeReferralSource}
                  />
                  {formError.referralSource ? (
                    <div className="error">Please select an option.</div>
                  ) : (
                    <div className="error">&nbsp;</div>
                  )}
                </div>
              </>
            )}
            <div className="quiz-guest-booked mt-0px">
              <div className="quiz-question-header">Where are you in your planning?</div>
              <div className="stage-wrapper">
                {STAGES.map((item) => (
                  <Radio
                    key={item.value}
                    id={item.value}
                    value={item.value}
                    name="planning"
                    classNames={{ container: 'ml-0px mr-16px mb-16px', label: 'basic' }}
                    checked={stage === item.value}
                    onChange={handleChangeStage}
                  >
                    {item.label}
                  </Radio>
                ))}
              </div>
            </div>
          </div>
          <div className="action-box-wrapper no-flex-zone mt-12px pb-70px">
            <button
              disabled={nextDisabled || loading}
              className={`button ${loading ? 'loading' : ''}`}
              onClick={handleNext}
            >
              Check availability
            </button>
            <p className="get-started-section__compliance">
              By signing up via text, you agree to receive recurring automated marketing text messages from Poppy
              Flowers at the cell number used when signing up. Consent is not a condition of any purchase. Msg & data
              rates may apply. Unsubscribe at anytime. See{' '}
              <a href={REDIRECT_PATH.TERMS} target="_blank" rel="noreferrer">
                Terms
              </a>
              {' & '}
              <a href={REDIRECT_PATH.PRIVACY} target="_blank" rel="noreferrer">
                Privacy.
              </a>
            </p>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
