import { IOption } from 'src/types'

export const STAGES: IOption[] = [
  {
    label: 'Just getting started',
    value: 'browsing',
  },
  {
    label: 'Comparing options',
    value: 'vendor_selection',
  },
  {
    label: 'Ready to book',
    value: 'ready_to_buy',
  },
]

export const NEW_STAGES: IOption[] = [
  {
    label: 'Ready to book our florist',
    value: 'ready_to_buy',
  },
  {
    label: 'Comparing potential florists',
    value: 'vendor_selection',
  },
  {
    label: 'Exploring our style and vision',
    value: 'browsing',
  },
]

export const REFERRAL_SOURCES: IOption[] = [
  { value: 'instagram', label: 'Instagram' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'pinterest', label: 'Pinterest' },
  { value: 'the_knot', label: 'The Knot' },
  { value: 'weddingwire', label: 'WeddingWire' },
  { value: 'zola', label: 'Zola' },
  { value: 'friend_or_family', label: 'Friend or Family' },
  { value: 'google_search', label: 'Google Search' },
  { value: 'other', label: 'Other' },
]

export const STEM_ALT_COLORS_AVAILABLE = [
  'Chrysanthemum',
  'Astrantia',
  'Lisianthus',
  'Ranunculus',
  'Scabiosa',
  'Spray Rose',
  'Rose',
  'Stock',
  'Butterfly Ranunculus',
  'Snapdragon',
  'Aster Matsumoto',
  'Spider Mum',
  'Delphinium',
  'Amaranthus',
  'Bunny Tails',
  'Anemone',
  'Hydrangea',
  'Freesia',
  'Lisianthus',
  'Carnation',
]

export enum ProposalActionKey {
  SHARE_INSPIRATION = 'SHARE_INSPIRATION',
  MEET_CONSULTANT = 'MEET_CONSULTANT',
  CUSTOMIZE_PROPOSAL = 'CUSTOMIZE_PROPOSAL',
  RESERVE_DATE = 'RESERVE_DATE',
  CONFIRM_ORDER = 'CONFIRM_ORDER',
  CONFIRM_LOGISTICS = 'CONFIRM_LOGISTICS',
  MEET_MANAGER = 'MEET_MANAGER',
  LAST_CALL = 'LAST_CALL',
}

export enum ProposalActionTaskSet {
  TASK_SET_1 = 'TASK_SET_1',
  TASK_SET_2 = 'TASK_SET_2',
}

export enum NP_EXPERIMENT {
  NP_PROGRESS_BAR = 'NP_progress_bar',
  V1_OVERVIEW_PAGE = 'v1_overview_page',
}

export const DUE_DATE_PLACEHOLDER = '@dueDate'

export const PROPOSAL_ACTION_ITEMS_TITLE = {
  [ProposalActionKey.SHARE_INSPIRATION]: 'Share Inspiration',
  [ProposalActionKey.CUSTOMIZE_PROPOSAL]: 'Customize your proposal',
  [ProposalActionKey.MEET_CONSULTANT]: 'Meet with your Floral Consultant',
  [ProposalActionKey.RESERVE_DATE]: 'Reserve your date',
  [ProposalActionKey.CONFIRM_ORDER]: 'Confirm your order',
  [ProposalActionKey.CONFIRM_LOGISTICS]: 'Confirm Day-Of Details',
  [ProposalActionKey.MEET_MANAGER]: 'Meet with your Floral Manager ',
  [ProposalActionKey.LAST_CALL]: 'Last Call for Small Changes',
}

export const PROPOSAL_ACTION_ITEMS_DESCRIPTION = {
  [ProposalActionKey.SHARE_INSPIRATION]:
    'Upload images to help our Floral Consultant curate the right florals for you.',
  [ProposalActionKey.CUSTOMIZE_PROPOSAL]:
    'Choose flowers and leave comments and feedback for your Floral Consultant as well!',
  [ProposalActionKey.MEET_CONSULTANT]:
    'Your Floral Consultant will bring your wedding flower vision to life and answer any questions you have.',
  [ProposalActionKey.RESERVE_DATE]: 'Make your deposit, the sooner the better to secure your date.',
  [ProposalActionKey.CONFIRM_ORDER]: 'Select the payment plan that works for you. Due by @dueDate.',
  [ProposalActionKey.CONFIRM_LOGISTICS]:
    'Share the details on the My Details page so we can provide a hassle-free experience. Due by @dueDate.',
  [ProposalActionKey.MEET_MANAGER]:
    'Your Floral Manager will finalize what they need to make your day a breeze. Due by @dueDate.',
  [ProposalActionKey.LAST_CALL]: 'Message us with any final tweaks for your special day. Due by @dueDate.',
}

export enum QuizExperience {
  LAST_NAME_HIDDEN = 'last_name_hidden',
  ATTRIBUTION_HIDDEN = 'attribution_hidden',
  X4_QUIZ_DETAILS = 'x4_quiz_details',
}
