/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../../contextAPI'
import { PATH_URL } from 'src/helpers'

interface quizResponseI {
  key: string
  values: string[] | string
  isUnknown: boolean
}

export const useGuestCount = () => {
  const history = useHistory()
  const { data, setQuizScreen, weddingDateData } = useContext(QuizQuestionContext)
  const [guestCount, setGuestCount] = useState('0')

  const handleNext = async (value: string) => {
    const currentTempPoppyData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    let currentQuizResponseFromLocal = currentTempPoppyData.quizResponses || null
    if (currentQuizResponseFromLocal && currentQuizResponseFromLocal.length > 0) {
      const guestIndex = currentQuizResponseFromLocal.findIndex((e: quizResponseI) => e.key === 'GUEST_COUNT')
      if (guestIndex > -1) {
        currentQuizResponseFromLocal[guestIndex].values = value
        currentQuizResponseFromLocal[guestIndex].isUnknown = false
      } else {
        currentQuizResponseFromLocal.push({
          key: 'GUEST_COUNT',
          values: value,
          isUnknown: false,
        })
      }
    } else {
      currentQuizResponseFromLocal = [{ key: 'GUEST_COUNT', values: value, isUnknown: false }]
    }
    localStorage.setItem(
      'tempPoppySessionData',
      JSON.stringify({
        ...currentTempPoppyData,
        quizResponses: currentQuizResponseFromLocal,
      })
    )
    history.push({ pathname: PATH_URL.NEW_BUDGET_PAGE })
    setQuizScreen(SCREEN_QUIZ_QUESTION.NEW_BUDGET_PAGE)
  }

  const handleBack = async () => {
    if (window.history.length > 1) {
      history.goBack()
    } else {
      history.push({ pathname: PATH_URL.PLANNING_STAGE })
      setQuizScreen(SCREEN_QUIZ_QUESTION.PLANNING_STAGE)
    }
  }

  const handleRecordGuestCount = async (value: string) => {
    setGuestCount(value)
    handleNext(value)
  }

  useEffect(() => {
    const dataFromLocal = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    const quizResponsesFromLocal = dataFromLocal?.quizResponses || null
    const guestDataFromLocal = quizResponsesFromLocal?.find((e: quizResponseI) => e.key === 'GUEST_COUNT')
    setGuestCount(data.guestCount || guestDataFromLocal?.values || '')
  }, [data.guestCount])

  return {
    data,
    weddingDateData,
    handleNext,
    handleBack,
    handleRecordGuestCount,
    guestCount,
    setGuestCount,
  }
}
