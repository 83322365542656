import React, { useEffect } from 'react'
import { useColorPaletteComponent } from './useColorPaletteComponent.hook'
import { QuizQuestionWrapper } from '../../QuizQuestionWrapper'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './style.scss'

export const dropdownStyles = {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  control: (styles: any) => ({ ...styles, backgroundColor: '#fff', paddingTop: '0px' }),
  option: (styles: any, { isDisabled }: any) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      fontFamily: 'LTC Caslon',
      color: '#231F20',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: '#eb4b32',
        color: '#fff',
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#eb4b32',
        color: '#fff',
      },
    }
  },
  menu: (styles: any) => {
    return {
      ...styles,
      backgroundColor: '#F2F1EA',
    }
  },
}

export const ColorPaletteComponentV2 = () => {
  const {
    nextDisabled,
    handleNext,
    handleBack,
    colorPalettes,
    hardCodedColorPalettes,
    setPaletteSelected,
    paletteSelected,
    handleNextWithValue,
  } = useColorPaletteComponent()

  useEffect(() => {
    const currentTempData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    const currentColorPalette = currentTempData?.colorPalette
    if (currentColorPalette) {
      setPaletteSelected(currentColorPalette)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!hardCodedColorPalettes && !colorPalettes.length) {
    return <></>
  }

  const palettesToRender = hardCodedColorPalettes || colorPalettes;

  return (
    <QuizQuestionWrapper
      titleStep="COLOR PALETTE"
      title="Which floral palette speaks to you?"
      subtitle=""
      nextAction={{
        onAction: handleNext,
        disabled: nextDisabled,
      }}
      backAction={{
        onAction: handleBack,
      }}
      step={1}
      total={7}
      className="quiz-guest-colors"
      showBgFlower={false}
    >
      <div className="quiz-guest-colors_flowers quiz-guest-colors-palette">
        <div className={`poppy-wedding ${paletteSelected && 'selectedPalette'}`}>
          <div className="flower-wrapper">
            {(
              <>
                <div className="new-color-palette-v2">
                  {palettesToRender.map((e, index) => (
                    <div className="color-palette-item-wrapper" key={index}>
                      <div
                        className={`color-palette-item ${paletteSelected?.id === e.id ? 'selected' : ''}`}
                        style={{ backgroundImage: `url(${e.image || e.overviewCarousels[0].imageUrl})` }}
                        onClick={() => handleNextWithValue(colorPalettes[index])}
                      >
                        <div className="color-showcase">
                          {e.colors.map((c: any, i: any) => (
                            <div
                              className="style-main-color"
                              key={`style-main-color-${i}`}
                              style={{ backgroundColor: c.hex }}
                            ></div>
                          ))}
                        </div>
                      </div>
                      <div className="palette-name">{e.name}</div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </QuizQuestionWrapper>
  )
}
