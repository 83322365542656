import React from 'react'
import { QuizQuestionWrapper } from '../../QuizQuestionWrapper'
import { useAvailability } from './Availability.hook'
import CustomDatePicker from './components/CustomDatePicker'
import { NewLocation } from '../NewLocation/NewLocation'

import './style.scss'
import { EVENT_LOCATION_TYPE } from 'src/types'
import { Unserviceable } from '../../Unserviceable'

export const Availability = () => {
  const {
    nextDisabled,
    handleNext,
    handleBack,
    loading,
    selectedDate,
    setSelectedDate,
    handleChange,
    locationSelected,
    handleGGPlaceSelected,
    locationHasValue,
    handleBlur,
    handleFocus,
    serviceable,
    setServiceable,
    unserviceableReason,
    setUnserviceableReason,
  } = useAvailability()

  const handleServiceabilityClose = () => {
    setServiceable(true)
    setUnserviceableReason(undefined)
  }

  return (
    <>
      <QuizQuestionWrapper
        titleStep="AVAILABILITY"
        title="When’s the big day?"
        backAction={{
          onAction: handleBack,
        }}
        nextAction={{
          onAction: handleNext,
          disabled: nextDisabled,
        }}
        step={2}
        total={7}
        className="request-a-proposal mobile-availability"
      >
        {!serviceable && <Unserviceable unserviceableReason={unserviceableReason} handleClose={handleServiceabilityClose}/>}
        <div className="request-a-proposal-wrapper availability-page">
          <CustomDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate}></CustomDatePicker>
          <div className="input-wrapper for-availability">
            <div className={`floating-label-wrapper ${locationHasValue ? 'has-value' : ''}`}>
              <label className="label">Wedding location</label>
              <NewLocation
                handleChange={handleChange}
                locationSelected={locationSelected}
                handleGGPlaceSelected={handleGGPlaceSelected}
                inputProps={{ showLabel: false, onBlur: () => handleBlur(), onFocus: () => handleFocus() }}
                locationType={EVENT_LOCATION_TYPE.CEREMONY}
              />
            </div>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <button
              className={`check-availability-btn ${loading ? 'loading' : ''}`}
              disabled={nextDisabled || loading}
              onClick={handleNext}
            >
              Check availability
            </button>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
