// import React, { useState } from 'react'
import React from 'react'

import textLogo from 'src/assets/images/text_logo.png'
// import flowerArch from 'src/assets/images/Poppy_Arch_Mark_Linen.svg'
// import navFlowes from 'src/assets/images/nav-flowers.png'
import './HeaderStyle.scss'
import { REDIRECT_PATH, redirect } from 'src/helpers'
import { shouldShowSectionHeader } from 'src/helpers'

export const Header = () => {
  // const [open, setOpen] = useState<boolean>(false)

  const redirectToHome = () => {
    redirect(REDIRECT_PATH.HOME)
  }

  // return !open ? (
  //   <div className="nav">
  //     <div className="section-header">
  //       <img className="text-logo" src={textLogo} alt="text logo" onClick={redirectToHome} />
  //       {/* <div
  //         className="btn-menu"
  //         onClick={() => {
  //           setOpen(true)
  //         }}
  //       >
  //         MENU
  //       </div> */}
  //     </div>
  //   </div>
  // ) : (
  //   <div className="nav">
  //     <div className="open-nav-wrapper">
  //       <div className="section-header">
  //         <div
  //           className="btn-menu"
  //           onClick={() => {
  //             setOpen(false)
  //           }}
  //         >
  //           CLOSE
  //         </div>
  //       </div>
  //       <div className="nav-content">
  //         <div className="nav-links">
  //           <a href={REDIRECT_PATH.HOME}>
  //             <h1> Home </h1>
  //           </a>
  //           <a href={REDIRECT_PATH.WEDDINGS}>
  //             <h1> Weddings </h1>
  //           </a>
  //           <a href={REDIRECT_PATH.SHOP}>
  //             <h1> Shop </h1>
  //           </a>
  //           <a href={REDIRECT_PATH.BlOG}>
  //             <h1>Blog </h1>
  //           </a>
  //           <a href={REDIRECT_PATH.ABOUT}>
  //             <h1> About </h1>
  //           </a>
  //         </div>
  //         <div className="flower-arch-container">
  //           <img className="flower-arch" src={flowerArch} alt="Poppy Arch Logo" />
  //         </div>
  //         <div>
  //           <img className="flowers" src={navFlowes} alt="Poppy Arch Logo" />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )

  return (
    <>
      {shouldShowSectionHeader() && (
        <>
          <div className="nav">
            <div className="section-header">
              <img className="text-logo" src={textLogo} alt="text logo" onClick={redirectToHome} />
            </div>
          </div>
        </>
      )}
    </>
  )
}
