/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { INewRangeSliderOptionItem } from 'src/common/components/slider'

import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from 'src/pages/quiz/contextAPI'

import slider500Web from 'src/assets/images/Slider_500_Web.png'
import slider1500Web from 'src/assets/images/Slider_1500_Web.png'
import slider2500Web from 'src/assets/images/Slider_2500_Web.png'
import slider3500Web from 'src/assets/images/Slider_3500_Web.png'
import slider4500Web from 'src/assets/images/Slider_4500_Web.png'
import { PATH_URL } from 'src/helpers'

export const BUDGET_500_INDEX = 0

export const budgets: INewRangeSliderOptionItem[] = [
  {
    label: '$1,750',
    value: 1750,
    image: slider500Web,
  },
  {
    label: '$2,000',
    value: 2000,
    image: slider500Web,
  },
  {
    label: '$3,000',
    value: 3000,
    image: slider1500Web,
  },
  {
    label: '$4,000',
    value: 4000,
    image: slider2500Web,
  },
  {
    label: '$5,250',
    value: 5250,
    image: slider3500Web,
  },
  {
    label: '$6,500',
    value: 6500,
    image: slider4500Web,
  },
  {
    label: '$7,500',
    value: 7500,
    image: slider4500Web,
  },
]

interface quizResponseI {
  key: string
  values: string[] | string
}

export const useNewBudget = () => {
  const history = useHistory()
  const { data, setQuizScreen } = useContext(QuizQuestionContext)

  const tempPoppyData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
  const budgetFromLocal = tempPoppyData.quizResponses.find((e: quizResponseI) => e.key === 'BUDGET')?.values[0] || 3000

  const [budgetSelected, setBudgetSelected] = useState<number>(
    parseInt(budgetFromLocal) ?? data.budget ?? +budgets[2].value
  )

  const [imgData, setImgData] = useState<INewRangeSliderOptionItem>({
    label: '$1,750',
    value: 3000,
    image: slider500Web,
  })

  const handleNext = async () => {
    const currentTempPoppyData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    let currentQuizResponseFromLocal = currentTempPoppyData.quizResponses || null
    if (currentQuizResponseFromLocal && currentQuizResponseFromLocal.length > 0) {
      const budgetIndex = currentQuizResponseFromLocal.findIndex((e: quizResponseI) => e.key === 'BUDGET')
      if (budgetIndex > -1) {
        currentQuizResponseFromLocal[budgetIndex].values = [budgetSelected.toString()]
      } else {
        currentQuizResponseFromLocal.push({
          key: 'BUDGET',
          values: [budgetSelected.toString()],
        })
      }
    } else {
      currentQuizResponseFromLocal = [{ key: 'BUDGET', values: [budgetSelected.toString()] }]
    }
    localStorage.setItem(
      'tempPoppySessionData',
      JSON.stringify({
        ...currentTempPoppyData,
        quizResponses: currentQuizResponseFromLocal,
      })
    )
    history.push({ pathname: PATH_URL.CONTACT_INFO })
    setQuizScreen(SCREEN_QUIZ_QUESTION.CONTACT_INFO)
  }

  const handleBack = () => {
    if (window.history.length > 1) {
      history.goBack()
    } else {
      history.push({ pathname: PATH_URL.GUEST_COUNT })
      setQuizScreen(SCREEN_QUIZ_QUESTION.GUEST_COUNT)
    }
  }

  const nextDisabled = useMemo(() => {
    return !budgetSelected || +budgetSelected < 1750
  }, [budgetSelected])

  useEffect(() => {
    const foundedBudget = budgets.findIndex((bd) => budgetSelected === bd.value)
    if (foundedBudget > 0) {
      setImgData(budgets[foundedBudget])
    } else {
      const firstLargeBudget = budgets.findIndex((bd) => bd.value > budgetSelected)
      if (firstLargeBudget > 0) {
        setImgData(budgets[firstLargeBudget - 1])
      } else {
        setImgData(budgets[budgets.length - 1])
      }
    }
  }, [budgetSelected])

  return {
    nextDisabled,
    budgetSelected,
    handleBack,
    handleNext,
    setBudgetSelected,
    imgData,
  }
}
