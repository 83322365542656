import React from 'react'
import { QuizQuestionWrapper } from '../../QuizQuestionWrapper'
import { OutlinedTextField } from 'src/common/components'
import { useYourDetails } from './YourDetails.hook'
import './YourDetails.scss'

const disabledDays = new Date()
disabledDays.setDate(disabledDays.getDate() + 1)

export const YourDetails = () => {
  const { weddingDateData, nextDisabled, formError, handleNext, handleBack, handleChangeInput } = useYourDetails()

  const currentTempPoppySessionData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
  const currentTotalWeddingFromLocal = currentTempPoppySessionData.totalWeddingNumber || 0
  const currentVenueNameFromLocal = currentTempPoppySessionData.venueName || ''

  const renderYourName = () => {
    return (
      <OutlinedTextField
        wrapperClass="mb-10px"
        id="fullNameId"
        value={weddingDateData.fullName || ''}
        type="text"
        name="fullName"
        onChange={handleChangeInput}
        label="Your name"
        isError={formError.fullName}
        errorMsg="Your name is required!"
      />
    )
  }

  const renderPartnerName = () => {
    return (
      <OutlinedTextField
        wrapperClass="mb-10px"
        id="partnerName"
        value={weddingDateData.partnerName || ''}
        type="text"
        name="partnerName"
        onChange={handleChangeInput}
        label="Partner's name"
        isError={formError.partnerName}
        errorMsg="Your partner's name is required!"
      />
    )
  }

  return (
    <>
      <QuizQuestionWrapper
        titleStep="YOUR DETAILS"
        title="We're available!"
        subtitle={
          currentTotalWeddingFromLocal > 0
            ? `We've done ${currentTotalWeddingFromLocal} wedding${
                currentTotalWeddingFromLocal === 1 ? '' : 's'
              } at ${currentVenueNameFromLocal}.`
            : ''
        }
        nextAction={{
          disabled: true,
        }}
        backAction={{
          onAction: handleBack,
        }}
        customCss={{
          title: 'title-bold',
          content: 'your-details-wrapper',
        }}
        step={3}
        total={7}
      >
        <div className="your-details-section mt-24px mb-30px">
          <div className="form-title">Who’s getting married?</div>
          <div className="customer-name">{renderYourName()}</div>
          <div className="partner-name">{renderPartnerName()}</div>
          <div className="action-box-wrapper">
            <button disabled={nextDisabled} className={`button`} onClick={handleNext}>
              Next
            </button>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
