import React, { useRef, useState } from 'react'
import { useColorsFlowers } from './ColorsFlowers.hook'
import { QuizQuestionWrapper } from './QuizQuestionWrapper'
import { OptionProps, components, SingleValueProps } from 'react-select'
import Slider, { Settings } from 'react-slick'
import nextArrow from 'src/assets/images/nextArrow.png'
import prevArrow from 'src/assets/images/prevArrow.png'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { isMobile } from 'src/helpers'
import { ColorPalette } from 'src/types'

export const dropdownStyles = {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  control: (styles: any) => ({ ...styles, backgroundColor: '#fff', paddingTop: '0px' }),
  option: (styles: any, { isDisabled }: any) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      fontFamily: 'LTC Caslon',
      color: '#231F20',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: '#eb4b32',
        color: '#fff',
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#eb4b32',
        color: '#fff',
      },
    }
  },
  menu: (styles: any) => {
    return {
      ...styles,
      backgroundColor: '#F2F1EA',
    }
  },
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColorOption = ({ data, ...props }: OptionProps<ColorPalette, boolean>) => {
  const colorPalette: ColorPalette = data
  return (
    <components.Option data {...props}>
      <div className="color-option">
        <div className="palette">
          {colorPalette.colors.map((i) => (
            <span key={i.hex} className="color-item" style={{ backgroundColor: i.hex, width: `${i.width}px` }} />
          ))}
        </div>
        <span className="palette-name">{colorPalette.name}</span>
      </div>
    </components.Option>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColorSingleValue = ({ ...props }: SingleValueProps<ColorPalette>) => {
  const value = props.getValue()
  return (
    <components.SingleValue {...props} className="color-value-container">
      {value.map((selectedColor) => (
        <div key={selectedColor.id} className="color-value">
          <div className="palette">
            {selectedColor.colors.map((i) => (
              <span key={i.hex} className="color-item" style={{ backgroundColor: i.hex, width: `${i.width}px` }} />
            ))}
          </div>
          <span className="palette-name">{selectedColor.name}</span>
        </div>
      ))}
    </components.SingleValue>
  )
}

const CustomNextArrow = (props: any) => {
  return (
    <div {...props}>
      <img src={nextArrow} alt="next arrow" />
    </div>
  )
}

const CustomPrevArrow = (props: any) => {
  return (
    <div {...props}>
      <img src={prevArrow} alt="prev arrow" />
    </div>
  )
}

const GALLERY_COL = 6

export const ColorsFlowers = () => {
  const {
    nextDisabled,
    handleNext,
    handleBack,
    colorPalettes,
    setPaletteSelected,
    paletteSelected,
  } = useColorsFlowers()

  const [activeIndex, setActiveIndex] = useState(0)
  const bgSliderRef = useRef<Slider>(null)
  const innerSliderRef = useRef<Slider>(null)
  const bgSlideSettings: Settings = {
    dots: false,
    infinite: false,
    swipe: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const innerSlideSettings: Settings = {
    dots: false,
    infinite: true,
    swipe: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (_current: number, next: number) => {
      setActiveIndex(next)
      setPaletteSelected((colorPalettes || [])[next] as ColorPalette)
      bgSliderRef.current?.slickGoTo(next)
    },
  }

  if (!colorPalettes.length) {
    return <></>
  }

  return (
    <QuizQuestionWrapper
      titleStep="YOUR COLORS"
      title="Pick your color palette"
      subtitle="Color choices can be customized later."
      nextAction={{
        onAction: handleNext,
        disabled: nextDisabled,
      }}
      backAction={{
        onAction: handleBack,
      }}
      step={4}
      total={4}
      className="quiz-guest-colors"
      showBgFlower={false}
    >
      <div className="quiz-guest-colors_flowers">
        <div className={`poppy-wedding ${paletteSelected && 'selectedPalette'}`}>
          <div className="flower-wrapper special">
            {!paletteSelected ? (
              <div className="choose-start-card">
                <span className="choose-start-text">Choose a palette to see photos of real Poppy weddings</span>
              </div>
            ) : (
              <Slider {...bgSlideSettings} className="bg-slider " ref={bgSliderRef} initialSlide={activeIndex}>
                {!isMobile &&
                  colorPalettes.map((cp) => (
                    <div className="moodboard-grid" key={cp.id}>
                      {Array.from(Array(GALLERY_COL).keys()).map((val) => (
                        <div className="moodboard-grid-col" key={val}>
                          <img
                            src={cp.moodboardImages[val]?.imageUrl}
                            alt={cp.moodboardImages[val]?.imageUrl}
                            className={`moodboard-image-${val + 1}`}
                          />
                          <img
                            src={cp.moodboardImages[val + GALLERY_COL]?.imageUrl}
                            alt={cp.moodboardImages[val + GALLERY_COL]?.imageUrl}
                            className={`moodboard-image-${val + GALLERY_COL + 1}`}
                          />
                        </div>
                      ))}
                    </div>
                  ))}

                {isMobile &&
                  colorPalettes.map((cp) => (
                    <div className="moodboard-grid" key={cp.id}>
                      {Array.from(Array(GALLERY_COL - 2).keys()).map((val) => (
                        <div className="moodboard-grid-col" key={val}>
                          <img
                            src={cp.moodboardImages[val === 3 ? val + 1 : val]?.imageUrl}
                            alt={cp.moodboardImages[val === 3 ? val + 1 : val]?.imageUrl}
                            className={`moodboard-image-${val === 3 ? val + 2 : val + 1}`}
                          />
                          <img
                            src={cp.moodboardImages[val === 0 ? val + 3 : val + 4]?.imageUrl}
                            alt={cp.moodboardImages[val === 0 ? val + 3 : val + 4]?.imageUrl}
                            className={`moodboard-image-${val === 0 ? val + 4 : val + 5}`}
                          />
                          <img
                            src={cp.moodboardImages[val + 8]?.imageUrl}
                            alt={cp.moodboardImages[val + 8]?.imageUrl}
                            className={`moodboard-image-${val + 9}`}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
              </Slider>
            )}
            <div className="inner-slider-wrapper">
              <div className="label">CHOOSE A PALETTE</div>
              <Slider
                {...innerSlideSettings}
                className="inner-slider"
                ref={innerSliderRef}
                nextArrow={<CustomNextArrow />}
                prevArrow={<CustomPrevArrow />}
                initialSlide={activeIndex}
              >
                {colorPalettes.map((cp) => (
                  <h2 key={cp.id}>{cp.name}</h2>
                ))}
              </Slider>
              <button className="button" disabled={nextDisabled} onClick={handleNext}>
                Start with {colorPalettes[activeIndex].name}
              </button>
            </div>
          </div>
        </div>
        {/* 
        <div className="action-box-wrapper mt-10px m-center pb-70px">
          <button disabled={nextDisabled} className="button white-space-wrap" onClick={handleNext}>
            Start with these colors
          </button>
        </div> */}
      </div>
    </QuizQuestionWrapper>
  )
}
