/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { NumericFormat } from 'react-number-format'
import { useNewBudget } from './NewBudget.hook'
import { QuizQuestionWrapper } from '../../QuizQuestionWrapper'
import './NewBudget.scss'
import { Helmet } from 'react-helmet'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props: any, ref: any) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      suffix={props.value >= 7500 ? '+' : ''}
    />
  )
})

export const NewBudget = () => {
  const { nextDisabled, handleNext, handleBack, budgetSelected, setBudgetSelected, imgData } = useNewBudget()

  const handleSliderChange = (sliderBudget: number) => {
    setBudgetSelected(sliderBudget)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBudgetSelected(event.target.value === '' ? 0 : Number(event.target.value))
  }

  const handleBlur = () => {
    if (+budgetSelected < 1750) {
      setBudgetSelected(1750)
    } else if (+budgetSelected > 7500) {
      setBudgetSelected(7500)
    }
  }

  const widthScreen = window.innerWidth

  return (
    <>
      <Helmet>
        <script>
          {`
            function gtag_report_conversion(params){
              gtag('event', 'conversion', params);
              return false;
            }
          `}
        </script>
      </Helmet>
      <QuizQuestionWrapper
        titleStep="YOUR BUDGET"
        title={widthScreen < 768 ? 'How much do you want to spend?' : 'How much are you planning to spend on flowers?'}
        subtitle="Our minimum is $1,750"
        nextAction={{
          disabled: true,
        }}
        backAction={{
          onAction: handleBack,
        }}
        step={6}
        total={7}
        customCss={{
          title: 'title-bold',
          content: 'budget-wrapper',
        }}
      >
        <div className="budget-section">
          <div className="quiz-budget">
            <div className="guests-wrapper text-transform-upper range-slider-option">
              <div className="img-wrapper bg-img-budget">
                {imgData && imgData.image && (
                  <div className="slider-image">
                    <img src={imgData.image} alt={imgData.label}></img>
                  </div>
                )}
              </div>
              <div className="quiz-budget-option-wrapper">
                <div className="quiz-budget-slider">
                  <p className="mb-16px">Poppy wedding flowers start at $1,750.</p>
                  <NumberFormatCustom
                    thousandSeparator={true}
                    value={budgetSelected}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    name="budgetNumber"
                    className="budget-input"
                  />
                  <div className="budget-slider mt-22px">
                    <Slider value={+budgetSelected} step={250} min={1750} max={7500} onChange={handleSliderChange} />
                  </div>
                </div>
              </div>
            </div>
            <div className="action-box-wrapper mt-34px mb-30px pb-70px">
              <button disabled={nextDisabled} className="button white-space-wrap" onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
