export const PATH_API = {
  getStylePreferenceImageOption: '/stylePreferenceImageOptions',
  postStyleImagePreferences: '/styleImagePreferences',
  postRecordQuizResponse: '/recordQuizResponse',
  postCalculateStyle: '/calculate',
  postSubmit: '/submit',
  getSessionId: (sessionId: string) => `/quizSessions/${sessionId}`,
  createSessionId: () => `/quizSessions`,
  checkAvailability: () => `/quizSessions/checkAvailability`,
  getCustomerInfo: (sessionId: string) => `/customerInfo/${sessionId}`,
  recordDuplicateSession: `/recordDuplicateReason`,
  postCheckoutSession: '/create-checkout-session',
  getQuizSessionData: '/quiz-questions-data',
  uploadFileToS3: '/upload-file',
  scheduledACall: '/scheduled-a-call',
  completeQuiz: (sessionId: string) => `/complete-quiz/${sessionId}`,
}

export const PATH_URL = {
  STYLE_INFO: '/your-style',
  QUIZ_QUESTION: '/quiz',
  EXISTING_PROPOSAL: '/existing',
  MICRO_WEDDING: '/microwedding',
  YOUR_POPPY_PROPOSAL: '/your-poppy-proposal',
  CALL_SCHEDULED: '/call-scheduled',
  $500_CUSTOMER: '/results',
  MOODBOARD: '/moodboard/:sessionId',
  POST_MOODBOARD: '/post-moodboard/:sessionId',
  SCHEDULE_A_CALL: '/schedule-a-call/:sessionId',
  SCHEDULE_YOUR_CONSULTATION: '/schedule-your-consultation',
  SWIPING: '/',
  DETAILS: '/details',
  PERSONAL_DETAIL: '/personal-details',
  WEDDING_DETAIL: '/wedding-details',
  AVAILABILITY_PAGE: '/availability',
  BUDGET_PAGE: '/budget',
  COLOR_PALETTE_PAGE: '/color-palette',
  QUIZ_END: '/thank-you',
  EXISTING_PROPOSAL_AFTER_SUBMITTED: '/existing-return',

  // new flow
  NEW_COLOR_PALETTE_PAGE: '/color-palettes',
  NEW_AVAILABILITY_PAGE: '/your-availability',
  YOUR_DETAILS: '/your-details',
  PLANNING_STAGE: '/planning-stage',
  GUEST_COUNT: '/guest-count',
  NEW_BUDGET_PAGE: '/your-budget',
  CONTACT_INFO: '/contact-info',
}

export const REDIRECT_PATH = {
  UNSERVICEABLE: 'https://www.poppyflowers.com/unserviceable',
  HOME: 'https://www.poppyflowers.com/',
  WEDDINGS: 'https://www.poppyflowers.com/wedding-flowers',
  REAL_WEDDINGS: 'https://www.poppyflowers.com/real-weddings',
  SHOP: 'https://shop.poppyflowers.com/',
  BlOG: 'https://www.poppyflowers.com/blog',
  ABOUT: 'https://www.poppyflowers.com/about',
  DIY: 'https://www.poppyflowers.com/diy',
  FAQS: 'https://www.poppyflowers.com/wedding-flowers#FAQ',
  FLORIST: 'https://www.poppyflowers.com/poppy-florist-network',
  PLANNERS: 'https://www.poppyflowers.com/event-planners',
  PRESS: 'https://www.poppyflowers.com/poppy-press',
  CORPORATE: 'https://www.poppyflowers.com/corporate-events',
  PRIVACY: 'https://www.poppyflowers.com/privacy-policy',
  REFUNDS: 'https://www.poppyflowers.com/delivery-and-refund-policy',
  TERMS: 'https://www.poppyflowers.com/terms-of-service',
  LOOK_BOOK: 'https://www.poppyflowers.com/look-book',
}

export const redirect = (url: string) => {
  window.location.href = url
}
