export const ENV = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL || '',
  REACT_APP_API_KEY: process.env.REACT_APP_API_KEY || '',
  REACT_APP_GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS || '',
  REACT_APP_GOOGLE_AW_CONVERSION: process.env.REACT_APP_GOOGLE_AW_CONVERSION || '',
  REACT_APP_GOOGLE_AW_CONVERSION_2: process.env.REACT_APP_GOOGLE_AW_CONVERSION_2 || '',
  REACT_APP_GOOGLE_AW_CONVERSION_START_QUIZ_EVENT_ID:
    process.env.REACT_APP_GOOGLE_AW_CONVERSION_START_QUIZ_EVENT_ID || '',
  REACT_APP_GOOGLE_AW_CONVERSION_COMPLETE_QUIZ_EVENT_ID:
    process.env.REACT_APP_GOOGLE_AW_CONVERSION_COMPLETE_QUIZ_EVENT_ID || '',
  REACT_APP_GOOGLE_AW_CONVERSION_LEAD_ID: process.env.REACT_APP_GOOGLE_AW_CONVERSION_LEAD_ID || '',
  REACT_APP_GOOGLE_AW_CONVERSION_CLICK_CTA_BTN_ID: process.env.REACT_APP_GOOGLE_AW_CONVERSION_CLICK_CTA_BTN_ID || '',
  REACT_APP_FACEBOOK_PIXEL_ID: process.env.REACT_APP_FACEBOOK_PIXEL_ID || '',
  REACT_APP_ENV: process.env.REACT_APP_ENV || 'dev',
  REACT_APP_SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE || '',
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  REACT_APP_CALENDLY_URL: process.env.REACT_APP_CALENDLY_URL || '',
  REACT_APP_ZAP_URL: process.env.REACT_APP_ZAP_URL || '',
  NODE_ENV: process.env.NODE_ENV || 'development',
  REACT_APP_SHOPIFY_URL: process.env.REACT_APP_SHOPIFY_URL || '',
  START_CONVERSATION_EXPERIMENT: process.env.REACT_APP_START_CONVERSATION_EXPERIMENT === 'true' || false,
  PREMERCHANDIZED_KITS: process.env.REACT_APP_PREMERCHANDIZED_KITS === 'true' || false,
  INTEGRATED_FIREWORK_EXPERIENCE: localStorage.getItem('INTEGRATED_FIREWORK_EXPERIENCE') === 'true',
  ENGAGEMENT_OPTION_EXPERIMENT_ID: process.env.REACT_APP_ENGAGEMENT_OPTION_EXPERIMENT_ID || '',
  REACT_APP_OPT_CONTAINER_ID: process.env.REACT_APP_OPT_CONTAINER_ID || '',
  REACT_APP_GG_MAP_API_KEY: process.env.REACT_APP_GG_MAP_API_KEY || '',
  REACT_APP_GOOGLE_ANALYTICS_4: process.env.REACT_APP_GOOGLE_ANALYTICS_4 || '',
  REACT_APP_SERVICEBELL_KEY: process.env.REACT_APP_SERVICEBELL_KEY || '',
  REACT_APP_GUINEA_PIG_REDIRECT_URL: process.env.REACT_APP_GUINEA_PIG_REDIRECT_URL || '',
  CANNY_VALLEY_EXPERIMENT_PERCENT: process.env.REACT_APP_CANNY_VALLEY_EXPERIMENT_PERCENT || '0',
  MAXIMUM_UPLOAD_FILE_SIZE: 10485760, // Byte = 10 MB,
  CUSTOMER_PORTAL_FRONTEND_URL: process.env.REACT_APP_CUSTOMER_PORTAL_FRONTEND_URL || '',
  PUBLIC_URL: process.env.PUBLIC_URL || '',
  REACT_APP_DEFAULT_EXPERIENCE: process.env.REACT_APP_DEFAULT_EXPERIENCE || 'legacy',
}
