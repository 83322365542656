import React from 'react'
import { QuizQuestionWrapper } from '../../QuizQuestionWrapper'
import { useNewPlanningStage } from './NewPlanningStage.hook'
import { NEW_STAGES } from 'src/pages/shared'
import { RadioWithIcon } from 'src/common/components/input/RadioWithIcon'
import { ReactComponent as BouquetIcon } from 'src/assets/icons/bouquet-icon.svg'
import { ReactComponent as CompareItemsIcon } from 'src/assets/icons/compare-items-icon.svg'
import { ReactComponent as BinocularsIcon } from 'src/assets/icons/binoculars-icon.svg'
import './NewPlanningStage.scss'

const disabledDays = new Date()
disabledDays.setDate(disabledDays.getDate() + 1)

const decodeIcon = (value: string) => {
  switch (value) {
    case 'browsing':
      return <BinocularsIcon />
    case 'vendor_selection':
      return <CompareItemsIcon />
    case 'ready_to_buy':
      return <BouquetIcon />
  }
}

export const NewPlanningStage = () => {
  const { handleBack, stage, handleChangeStage, handleClickStage } = useNewPlanningStage()

  return (
    <>
      <QuizQuestionWrapper
        titleStep="PLANNING"
        title="Where are you in your floral planning?"
        nextAction={{
          disabled: true,
        }}
        backAction={{
          onAction: handleBack,
        }}
        customCss={{
          title: 'title-bold',
          content: 'new-planning-stage-wrapper',
        }}
        step={4}
        total={7}
      >
        <div className="new-planning-stage-section">
          {NEW_STAGES.map((item) => (
            <RadioWithIcon
              key={item.value}
              id={item.value}
              value={item.value}
              name="planning"
              classNames={{ container: '', label: 'basic' }}
              checked={stage === item.value}
              onChange={handleChangeStage}
              onClick={() => handleClickStage(item.value)}
            >
              <div className="radio-with-icon-content">
                <div className="icon">{decodeIcon(item.value)}</div>
                <div className="item-label">{item.label}</div>
              </div>
            </RadioWithIcon>
          ))}
          {/* <div className="action-box-wrapper">
            <button
              disabled={nextDisabled || loading}
              className={`button ${loading ? 'loading' : ''}`}
              onClick={handleNext}
            >
              Next
            </button>
          </div> */}
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
