/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../../../contextAPI'
import { API, IOption, ISubmitQuizQuestionPayload, ISubmitQuizQuestionResponse } from 'src/types'
import { EVENTS_FB_PIXEL, fbEvent, PATH_API, PATH_URL, validate } from 'src/helpers'
import { SESSION_NAME_LOCAL, useAppContext } from 'src/helpers/app'
import { REFERRAL_SOURCES } from 'src/pages/shared'
import { postHttp } from 'src/api'
import { ACTION_APP, ga4EventApp, gaEventApp } from 'src/helpers/googleAnalyticsService'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'
import { vwoEvent } from 'src/helpers/vwo'
import { ENV } from 'src/config/env'
import { isValidPhoneNumber } from 'src/common'

interface quizResponseI {
  key: string
  values: string[] | string
}

interface formError {
  emailAddress?: boolean
  referralSource?: boolean
  phoneNumber?: boolean
}

interface GuineaPigConfigValue {
  enabled: boolean
  budget: {
    enabled: boolean
    min: number
    max: number
  }
  colorPalette: {
    enabled: boolean
    colorPaletteIds: number[]
  }
  planningStage: {
    enabled: boolean
    planningStages: string[]
  }
}

const submitQuizResponseAPI = (submitData: ISubmitQuizQuestionPayload) => {
  return postHttp(PATH_API.postSubmit, submitData)
}

const MAX_TIME_TRACKING = 1500
const MAX_TIME_GOOGLE_TRACKING = 3000

const getShowGuineaPig = (
  budget: number,
  planningStage: string,
  paletteId: number,
  config: GuineaPigConfigValue | undefined
) => {
  if (!config || !config.enabled) return false

  return (
    (!config.budget.enabled || (budget >= config.budget.min && budget <= config.budget.max)) &&
    (!config.colorPalette.enabled || config.colorPalette.colorPaletteIds.includes(paletteId || -1)) &&
    (!config.planningStage.enabled || config.planningStage.planningStages.includes(planningStage || ''))
  )
}

export const useContactInfo = () => {
  const history = useHistory()
  const {
    setQuizScreen,
    weddingDateData,
    setWeddingDateDataState,
    referralSource,
    setReferralSourceState,
    setNewQuizData,
    setRecordResponse,
    setRedirectUrl,
  } = useContext(QuizQuestionContext)
  const { sessionID, storeURLParameters, handleChangeAppData } = useAppContext()
  const [formError, setFormError] = useState<formError>({})
  const [loading, setLoading] = useState(false)

  const isEmailError = useMemo(
    () => {
      return !validate.email((weddingDateData.emailAddress ?? '').trim()) || !!formError.emailAddress
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [weddingDateData.emailAddress]
  )

  const isReferralSourceError = useMemo(
    () => !validate.leastOneLetter(referralSource?.value || ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referralSource]
  )

  const isPhoneNumberError = useMemo(() => !isValidPhoneNumber((weddingDateData.phoneNumber ?? '').trim()), [
    weddingDateData.phoneNumber,
  ])

  const formInvalid = () => {
    setFormError({
      emailAddress: isEmailError,
      referralSource: isReferralSourceError,
      phoneNumber: isPhoneNumberError,
    })
    return isEmailError || isReferralSourceError || isPhoneNumberError
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setWeddingDateDataState({ ...weddingDateData, [name]: value })
    setFormError((data) => ({ ...data, [name]: false }))
  }

  const handleChangeReferralSource = (value: IOption | null) => {
    setReferralSourceState(value)
    setFormError((data) => ({ ...data, referralSource: false }))
  }

  const handleChangePhoneNumber = (value: string) => {
    setWeddingDateDataState({ ...weddingDateData, phoneNumber: value })
    setFormError((data) => ({ ...data, phoneNumber: false }))
  }

  const handleNext = async () => {
    if (formInvalid()) return
    setLoading(true)
    // add it to local first
    const oldTempData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    let oldQuizResponses = oldTempData?.quizResponses || []
    if (oldQuizResponses.length > 0) {
      const refIndex = oldQuizResponses.findIndex((data: quizResponseI) => data.key === 'REFERRAL_SOURCE')
      if (refIndex > -1) {
        oldQuizResponses[refIndex].values = referralSource?.value || ''
      } else {
        oldQuizResponses.push({
          key: 'REFERRAL_SOURCE',
          values: referralSource?.value || '',
        })
      }
    } else {
      oldQuizResponses = [{ key: 'REFERRAL_SOURCE', values: referralSource?.value || '' }]
    }
    localStorage.setItem(
      'tempPoppySessionData',
      JSON.stringify({
        ...oldTempData,
        customerInfo: {
          ...oldTempData.customerInfo,
          emailAddress: weddingDateData?.emailAddress?.trim() || '',
          phoneNumber: weddingDateData?.phoneNumber?.trim() || '',
        },
        quizResponses: oldQuizResponses,
      })
    )

    // process to the submit logic
    const currentTempPoppyData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')

    const currentQuizResponsesFromLocal = currentTempPoppyData?.quizResponses || null
    const customerInfoFromLocal = currentTempPoppyData?.customerInfo || null
    const eventVenuesFromLocal = currentTempPoppyData?.eventVenues || null
    const colorPaletteFromLocal = currentTempPoppyData?.colorPalette || null
    const colorPaletteIdFromLocal = colorPaletteFromLocal?.id

    const customerInfo = {
      emailAddress: weddingDateData.emailAddress?.trim() || '',
      firstName: weddingDateData.firstName?.trim() || customerInfoFromLocal.firstName?.trim() || '',
      lastName: weddingDateData.lastName?.trim() || customerInfoFromLocal.lastName?.trim() || '',
      partnerName: weddingDateData.partnerName?.trim() || customerInfoFromLocal.partnerName?.trim() || '',
      phoneNumber: weddingDateData.phoneNumber?.trim() || customerInfoFromLocal.phoneNumber?.trim() || '',
    }
    const eventVenues = eventVenuesFromLocal
    const quizQuestion = 'CONTACT_INFO'
    const quizResponses = currentQuizResponsesFromLocal
    const sessionId = sessionID || JSON.parse(localStorage.getItem('sessionID') || '')

    const payload = {
      customerInfo,
      eventVenues,
      experiment: [],
      quizQuestion,
      quizResponses,
      sessionId,
      colorPaletteId: colorPaletteIdFromLocal,
    }
    try {
      const recordResponse = await setNewQuizData(payload)
      if (recordResponse?.data) {
        setRecordResponse(recordResponse.data)
      }
      if (recordResponse?.data?.proposal?.hadProposal) {
        history.push({ pathname: PATH_URL.EXISTING_PROPOSAL })
        setQuizScreen(SCREEN_QUIZ_QUESTION.MET_BEFORE)
        return
      }
      await handleSubmit()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleBack = async () => {
    if (window.history.length > 1) {
      history.goBack()
    } else {
      history.push({ pathname: PATH_URL.NEW_BUDGET_PAGE })
      setQuizScreen(SCREEN_QUIZ_QUESTION.NEW_BUDGET_PAGE)
    }
  }

  const gTagSetUserData = useCallback((param: any) => {
    const report = (window as { [key: string]: any })['gtag']
    if (report) {
      report('set', 'user_data', param)
    }
  }, [])

  const handleSubmit = async () => {
    try {
      const calculateStyleResult: API<ISubmitQuizQuestionResponse> = await submitQuizResponseAPI({
        sessionId: sessionID,
      })

      const paletteId = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')?.colorPalette?.id || 0
      const budgetValue = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')?.quizResponses?.find(
        (data: quizResponseI) => data.key === 'BUDGET'
      )?.values
      const planningStage = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')?.quizResponses?.find(
        (data: quizResponseI) => data.key === 'PLANNING_STAGE'
      )?.values

      const showAfterQuiz = getShowGuineaPig(
        budgetValue,
        planningStage,
        +paletteId,
        calculateStyleResult.data.guineaPigConfig?.value
      )

      const leadValueConfig = calculateStyleResult.data.leadValueConfig
      const leadValuesByBudget = leadValueConfig?.leadValuesByBudget?.sort((a, b) => a.budget - b.budget) || []
      const leadValueToIndex = leadValuesByBudget.findIndex((l) => l.budget >= +budgetValue)
      const leadValueFrom = leadValueToIndex > 0 ? leadValuesByBudget[leadValueToIndex - 1] : undefined
      const leadValueTo = leadValueToIndex >= 0 ? leadValuesByBudget[leadValueToIndex] : undefined
      let leadValue: any
      if (leadValueFrom && leadValueTo) {
        const percent = (+budgetValue - leadValueFrom.budget) / (leadValueTo.budget - leadValueFrom.budget)
        if (leadValueFrom.value > leadValueTo.value) {
          leadValue = leadValueFrom.value - (leadValueFrom.value - leadValueTo.value) * percent
        } else {
          leadValue = (leadValueTo.value - leadValueFrom.value) * percent + leadValueFrom.value
        }
      }
      // If a style quiz session has a urlParam leadValueStrategy=regression (or there is no leadValueStrategy param), use the current approach.
      // If it has the leadValueStrategy=budget, use the older approach.
      // if (storeURLParameters?.leadValueStrategy !== 'budget') {
      //   leadValue = calculateStyleResult.data.leadValue
      // }

      const tracking = async () => {
        const gAdwords = (param: any) =>
          new Promise((rs) => {
            const report = (window as { [key: string]: any })['gtag_report_conversion']
            // We report adwords conversions by inserting a function into the <head> of the page.
            if (report) {
              report({ ...param, event_callback: rs })
              setTimeout(rs, MAX_TIME_GOOGLE_TRACKING)
            } else {
              rs(true)
            }
          })
        const ga4Event = (action: ACTION_APP, params: any = {}) =>
          new Promise((rs) => {
            ga4EventApp(action, { ...params, event_callback: rs })
            setTimeout(rs, MAX_TIME_TRACKING)
          })
        const _segmentTracking = (action: string) =>
          new Promise((rs) => {
            segmentTracking(action, {}, {}, rs)
            setTimeout(rs, MAX_TIME_TRACKING)
          })
        const pintrk = () =>
          new Promise((rs) => {
            const windowGlobal = window as any
            if (windowGlobal.pintrk) {
              windowGlobal.pintrk('track', 'lead', { value: leadValue }, function (didInit: boolean, error: any) {
                if (!didInit) {
                  console.log(error)
                }
                rs(true)
              })
            }
            setTimeout(rs, MAX_TIME_TRACKING)
          })
        // Cheat: timeout for lib don't support callback
        const funcNoCallback = () =>
          new Promise((rs) => {
            gaEventApp(ACTION_APP.QUIZ_SUBMITTED)
            gaEventApp(ACTION_APP.FULLY_QUALIFIED_LEAD)
            fbEvent(EVENTS_FB_PIXEL.QUIZ2COMPLETE, {
              value: leadValue,
              currency: 'USD',
            })
            vwoEvent()
            setTimeout(rs, MAX_TIME_TRACKING)
          })
        const promiseFunc = [
          gAdwords({
            value: leadValue,
            currency: 'USD',
            send_to: `${ENV.REACT_APP_GOOGLE_AW_CONVERSION}/${ENV.REACT_APP_GOOGLE_AW_CONVERSION_COMPLETE_QUIZ_EVENT_ID}`,
          }),
          gAdwords({
            value: leadValue,
            currency: 'USD',
            send_to: `${ENV.REACT_APP_GOOGLE_AW_CONVERSION_2}/${ENV.REACT_APP_GOOGLE_AW_CONVERSION_LEAD_ID}`,
          }),
          ga4Event(ACTION_APP.QUIZ_SUBMITTED),
          ga4Event(ACTION_APP.FULLY_QUALIFIED_LEAD),
          _segmentTracking(SegmentAction.SQ_QUIZ_SUBMITTED),
          _segmentTracking(SegmentAction.SQ_FULLY_QUALIFIED_LEAD),
          pintrk(),
          funcNoCallback(),
        ]
        await Promise.all(promiseFunc)
      }

      const tempPoppyDataFromLocal = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
      const customerFromLocal = tempPoppyDataFromLocal?.customerInfo || null

      if (calculateStyleResult.data.proposalUrl) {
        try {
          gTagSetUserData({
            email: customerFromLocal.emailAddress || '',
            address: {
              firstName: customerFromLocal.firstName || '',
              lastName: customerFromLocal.lastName || '',
            },
          })
          await tracking()
          console.log('tracking success')
        } catch (error) {}
        if (showAfterQuiz) {
          localStorage.setItem('tempPoppySessionData', '{}')
          setRedirectUrl(calculateStyleResult.data.proposalUrl)
          setQuizScreen(SCREEN_QUIZ_QUESTION.AFTER_QUIT_SUBMIT)
        } else {
          localStorage.setItem(SESSION_NAME_LOCAL, '')
          localStorage.setItem('tempPoppySessionData', '{}')
          window.location.href = calculateStyleResult.data.proposalUrl
        }
      } else {
        if (showAfterQuiz) {
          localStorage.setItem('tempPoppySessionData', '{}')
          setRedirectUrl(calculateStyleResult?.data?.redirectLink || `https://www.poppyflowers.com/`)
          setQuizScreen(SCREEN_QUIZ_QUESTION.AFTER_QUIT_SUBMIT)
        } else {
          localStorage.setItem(SESSION_NAME_LOCAL, '')
          localStorage.setItem('tempPoppySessionData', '{}')
          window.location.href = calculateStyleResult?.data?.redirectLink || `https://www.poppyflowers.com/`
        }
      }
      // Cheat: Single loading state during quiz submission
      // handleChangeAppData({ countLoadingTestimonial: -1 })
      if (showAfterQuiz) {
        handleChangeAppData({ countLoadingTestimonial: -1 })
      }
    } catch (error: any) {
      console.log('[handleSubmit]', error)
      handleChangeAppData({ countLoadingTestimonial: -1 })
      alert(error?.data?.message ?? 'Submit failed')
    }
  }

  const nextDisabled = useMemo(() => {
    const check = !weddingDateData || weddingDateData.emailAddress === undefined || isReferralSourceError

    return check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingDateData, isReferralSourceError])

  useEffect(() => {
    const tempDataFromLocal = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    const customerInfoFromLocal = tempDataFromLocal?.customerInfo || null
    if (customerInfoFromLocal) {
      setWeddingDateDataState({
        ...weddingDateData,
        emailAddress: customerInfoFromLocal?.emailAddress || '',
        phoneNumber: customerInfoFromLocal?.phoneNumber || '',
      })
    }
    const quizResponsesFromLocal = tempDataFromLocal?.quizResponses || null
    const refIndex = quizResponsesFromLocal?.findIndex((data: quizResponseI) => data.key === 'REFERRAL_SOURCE')
    if (refIndex > -1) {
      const refData = REFERRAL_SOURCES.find((e) => e.value === quizResponsesFromLocal[refIndex].values)
      if (refData) {
        setReferralSourceState(refData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    weddingDateData,
    handleNext,
    handleBack,
    formError,
    handleChangeInput,
    referralSource,
    handleChangeReferralSource,
    handleChangePhoneNumber,
    nextDisabled,
    loading,
  }
}
