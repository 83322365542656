import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ServiceBell from '@servicebell/widget'

import { API, IQuizSessionsResponse, IStringDynamicPrototype, SESSION_STATE } from 'src/types'
import { getHttp } from 'src/api'
import { init } from '../facebookPixelService'
import { ACTION_APP, ga4EventApp, gaEventApp } from '../googleAnalyticsService'
import { SegmentAction, segmentTracking } from '../segment'
import { PATH_API, PATH_URL } from '../path'
import { ENV } from 'src/config/env'

import { useAppContext } from './App.context'
import { generateSessionID } from './helper'

export const SESSION_NAME_LOCAL = 'sessionID'

export const useAppBase = () => {
  const {
    appData,
    setSessionID,
    setStoreURLParameters,
    handleChangeAppData,
    setQuizSession,
    setQuizExperience,
    setExperiment,
  } = useAppContext()
  const { isLoading, countLoading, countLoadingTestimonial } = appData

  const location = useLocation()

  const currentParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (location.pathname.includes(PATH_URL.MICRO_WEDDING)) {
      handleChangeAppData({ isMicroWedding: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    const randomQuizExperience = () => {
      const factor = Math.random()
      if (factor <= +ENV.CANNY_VALLEY_EXPERIMENT_PERCENT) setQuizExperience('CANNY_VALLEY')
    }
    const randomExperiment = () => {
      return []
    }

    const checkSessionId = async (sessionID: string): Promise<string> => {
      let newSessionID = sessionID
      try {
        const sessionResult: API<IQuizSessionsResponse> = await getHttp(PATH_API.getSessionId(newSessionID))
        switch (sessionResult.data.sessionState) {
          case SESSION_STATE.SUBMITTED:
            newSessionID = generateSessionID()
            if (sessionResult.data?.proposal?.hadProposal) {
              sessionStorage.setItem('proposalUrl', sessionResult.data.proposal.proposalUrl)
              window.location.href = PATH_URL.EXISTING_PROPOSAL_AFTER_SUBMITTED
            }
            break
          default:
            break
        }
        setQuizExperience(sessionResult.data.quizExperience)
        setExperiment(sessionResult.data.experiment)
        setQuizSession(sessionResult.data)
      } catch (error) {
        console.log('[checkSessionId]', error)
        newSessionID = generateSessionID()
        // await postHttp(PATH_API.createSessionId(), { sessionId: newSessionID })
        randomQuizExperience()
        setExperiment(randomExperiment())
        // history.push(PATH_URL.SWIPING)
      }

      return newSessionID
    }

    const initSessionID = async (searchParamsObject: IStringDynamicPrototype) => {
      let sessionID = searchParamsObject[SESSION_NAME_LOCAL] || localStorage.getItem(SESSION_NAME_LOCAL)

      if (!sessionID || searchParamsObject.quizData) {
        sessionID = generateSessionID()
        randomQuizExperience()
        setExperiment(randomExperiment())
        // history.push(PATH_URL.SWIPING)
      } else {
        sessionID = await checkSessionId(sessionID)
      }

      localStorage.setItem(SESSION_NAME_LOCAL, sessionID)
      setSessionID(sessionID)
    }
    const handleStoreURL = () => {
      let searchParamsObject: IStringDynamicPrototype = {}
      if (location.search) {
        searchParamsObject = Object.fromEntries(new URLSearchParams(location.search))
        setStoreURLParameters(searchParamsObject)
      }
      return searchParamsObject
    }

    const checkRunInitApp = () => {
      const urlDontCheck = [
        PATH_URL.YOUR_POPPY_PROPOSAL,
        PATH_URL.SCHEDULE_YOUR_CONSULTATION,
        PATH_URL.CALL_SCHEDULED,
        PATH_URL.$500_CUSTOMER,
        '/moodboard/',
        '/schedule-a-call/',
        '/post-moodboard/',
      ]
      const pathCheck = window.location.pathname
      return !urlDontCheck.some((e) => pathCheck.includes(e))
    }

    const initApp = async () => {
      handleChangeAppData({ isLoading: true })
      const searchParamsObject = handleStoreURL()
      await initSessionID(searchParamsObject)
      ServiceBell('init', ENV.REACT_APP_SERVICEBELL_KEY)
      handleChangeAppData({ isLoading: false })
    }

    if (checkRunInitApp()) {
      initApp()
    } else {
      handleChangeAppData({ isLoading: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    init()
    gaEventApp(ACTION_APP.QUIZ_LOADED)
    ga4EventApp(ACTION_APP.QUIZ_LOADED)
    segmentTracking(
      SegmentAction.SQ_QUIZ_LOADED,
      currentParams.has('quizData') ? { x4: 'skipped_details' } : { x4: 'disabled' }
    )
    segmentTracking(SegmentAction.SQ_QUIZ_LOADED, {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoading: isLoading || (countLoading || 0) > 0,
    isLoadingTestimonial: (countLoadingTestimonial || 0) > 0,
  }
}
